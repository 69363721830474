import React from "react";
//import 'bootstrap/dist/css/bootstrap.min.css';

// Component imports
import AppLayout from "../app_pages/AppLayout";

// Image imports

// CSS imports

export default class PainLoggerLayout extends React.Component {
  render() {
    return (
      <AppLayout
        userGuide="pain_logger/pain_logger_user_guide"
        privacyPolicy="pain_logger/pain_logger_privacy"
        homePage="pain_logger/pain_logger"
        faqPage="pain_logger/pain_logger_faq"
        newsPage="pain_logger/pain_logger_news"
//        pressPage="pain_logger/pain_logger_press"
        footerPositioning="bottom"
        appName="Pain Logger"
      >
        {this.props.children}
      </AppLayout>
    );
  }
}
